<!-- 二级页面-->
<template>
  <div class="secondPage publicPage">
    <div class="content">
      <div class="left">
        <div class="title">{{ firstTitle }}</div>
        <div class="itemBox">
          <div
            class="item"
            v-for="item in itemList"
            :key="item.id"
            @click="itemClick(item)"
            :class="{ active: activeItem.id == item.id }"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="top">
          <ititle :text="activeItem.title"></ititle>
          <div class="address">{{ address.join(">") }}</div>
        </div>
        <div class="pageBox">
          <div v-if="pageType == 1" style="height: 100%">
            <ul>
              <li v-for="page in pageList" :key="page.id" @click="pageClick(page)">
                <span class="page">>{{ page.title }}</span>
                <span class="time">{{ timefmt(page.write_time) }}</span>
              </li>
            </ul>
            <!-- <el-pagination
              @current-change="handleCurrentChange"
              :page-size="10"
              :current-page="currentPage"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination> -->
          </div>
          <div v-if="pageType == 0" style="height: 100%">
            <div class="pageHtml" v-html="pageInfo.content"></div>
          </div>
        </div>
      </div>
    </div>
    <sfoot></sfoot>
  </div>
</template>

<script>
import ititle from "../components/ititle.vue";
import sfoot from "./components/sfoot.vue";
import { first_title, get_title_article, get_article } from "../request/apis";
import dayjs from "dayjs";
export default {
  name: "",
  components: { ititle, sfoot },
  props: {},
  data() {
    return {
      total: 20,
      currentPage: 1,
      itemList: [],
      pageList: [],
      firstTitle: "",
      activeItem: {},
      address: ["当前位置:首页"],
      query: "",
      pageType: "",
      pageInfo: {},
    };
  },
  computed: {},
  watch: {
    //监听路由参数变化
    $route: {
      handler(val) {
        this.query = val.query;
        this.init(val.query);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    //切换页码
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    //点击二级标题
    itemClick(item) {
      this.activeItem = item;
      this.address[2] = item.title;
      this.pageType = item.return_type;
      if (item.return_type == 1) {
        //获取文章列表
        this.getPageList(item.id);
      } else {
        this.get_article(item.articles_id);
      }
    },
    //初始化参数
    init(data) {
      this.getFirstInfo(data.firstId);
    },
    //获取一级标题数据
    getFirstInfo(id) {
      first_title(id).then((res) => {
        this.firstTitle = res.title;
        this.address[1] = this.firstTitle;
        this.itemList = res.children;
        //二级标题点进来
        if (this.query.secondId) {
          let second = this.itemList.find((v) => v.id == this.query.secondId);
          this.itemClick(second);
        } else {
          //默认第一个
          this.itemClick(this.itemList[0]);
        }
      });
    },
    //根据二级标题获取文章列表
    getPageList(id) {
      get_title_article(id).then((res) => {
        this.pageList = res;
        this.pageList.sort(
          (a, b) => new Date(b.write_time).getTime() - new Date(a.write_time).getTime()
        );
      });
    },
    //获取文章信息
    get_article(id) {
      get_article(id).then((res) => {
        this.pageInfo = res;
      });
    },
    //时间格式化
    timefmt(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    //点击文章
    pageClick(page) {
      if (page.return_type == 1) {
        //url直接跳转
        window.open(page.url, "_blank");
        return;
      }
      this.$router.push({
        path: "/threePage",
        query: {
          pageId: page.id,
          address: this.address,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.secondPage {
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  box-sizing: border-box;
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url("../assets/图书馆背景图片.jpg");
    filter: blur(6px);
  }
  .content {
    width: 1100px;
    display: flex;
    height: calc(100vh - 200px);
    border-radius: 4px;
    overflow: hidden;
    .left {
      flex: 0 0 180px;
      background-color: #eee;
      height: 100%;
      .title {
        font-size: 24px;
        background-color: rgb(2, 79, 129);
        color: #fff;
        padding: 16px 0px;
        text-align: center;
      }
      .itemBox {
        height: calc(100% - 56px);
        overflow-y: auto;
      }
      .item {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #333;
        border-bottom: 1px solid #eee;
        cursor: pointer;
      }
      .active {
        background-color: rgb(88, 176, 235);
        color: #fff;
      }
    }
    .right {
      flex: 1;
      width: 0;
      background-color: #fff;
      box-sizing: border-box;
      padding: 0px 20px 10px 20px;
      flex-direction: column;
      /deep/ .ititle {
        padding: 0px;
        height: 40px;
        .text {
          font-size: 20px;
          font-weight: normal;
        }
        .line {
          height: 20px;
        }
      }
      .top {
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        .address {
          margin-left: auto;
          flex-shrink: 0;
        }
      }
      .pageBox {
        height: calc(100% - 42px);
        ul {
          height: calc(100% - 38px);
          overflow-y: auto;
          li {
            display: flex;
            padding: 12px 0px;
            border-bottom: 1px solid rgb(222, 221, 221);
            cursor: pointer;
            .time {
              margin-left: auto;
            }
            &:hover {
              .page {
                color: #f00;
              }
            }
          }
        }
        /deep/ .el-pagination {
          display: flex;
          justify-content: center;
        }
        .titleBox {
          display: flex;
          flex-direction: column;
          text-align: center;
          .title {
            font-size: 24px;
            font-weight: bold;
            padding: 20px 0px;
            text-align: center;
          }
        }
        .pageHtml {
          height: calc(100% - 40px);
          overflow-y: auto;
          box-sizing: border-box;
          padding: 20px;
          line-height: 2;
        }
      }
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .secondPage {
      flex-direction: column;
      margin-top: 20px;
      padding-bottom: 0px;
      .content {
        flex-direction: column;
        width: 100%;
        border-radius: 0px;
        .left {
          .title {
            font-size: 16px;
            padding: 8px 0px;
          }
          .itemBox {
            flex-direction: row;
            display: flex;
            height: auto;
            flex-wrap: wrap;
            .item {
              margin: 4px;
              padding: 0px 6px;
              font-size: 12px;
            }
          }
        }
        .right {
          width: 100%;
          padding: 0px;
          flex-direction: column;
          /deep/ .ititle {
            .text {
              font-size: 14px;
            }
          }
          .top {
            flex-direction: column;
            align-items: flex-start;
            .address {
              margin-left: 0px;
              font-size: 12px;
            }
          }
          .pageBox {
            height: 500px;
            ul {
              height: 500px;
            }
          }
        }
      }
    }
  }
}
</style>
